import React from "react"
import Ingress from "../components/Ingress"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Leadership from "../components/Leadership"
import Footer from "../components/Footer"

export default function AboutPage() {
  return (
    <Layout>
      <Seo title="About" />
      <Ingress />
      <Leadership />
      <div className="border-b border-white"></div>
      <Footer />
    </Layout>
  )
}
